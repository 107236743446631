import * as React from "react";
import Layout from "../../components/layout";
import Top50Posts from "../../components/top50Posts";

const BlogPage = () => {
  return (
    <Layout
      pageTitle="Blog - Daniel José Da Silva"
      description="Blog about Agile, Scrum and modern Tech Stacks"
      keywords="blog,agile,scrum,daniel,c#,scrum master,product owner"
    >
      <Top50Posts />
    </Layout>
  );
};

export default BlogPage;
