import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { mainColor } from "../shared/layout.module.css";
import { Row, Col } from "react-bootstrap";

export default function Top50Posts() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPosts(limit: 50, sort: { fields: date, order: DESC }) {
        nodes {
          url
          title
          date(formatString: "MMMM DD, YYYY")
          description
          mainImage {
            gatsbyImageData(
              aspectRatio: 0.5
              resizingBehavior: THUMB
              cornerRadius: 10
              width: 200
            )
            description
          }
        }
      }
    }
  `);

  return (
    <div className="pt-3 pb-2">
      <h2 className={mainColor}>Blog Latest Posts</h2>
      {data.allContentfulBlogPosts.nodes.map((node) => (
        <Row className="p-3" key={node.url}>
          <Col xs={1}>
            <GatsbyImage
              image={node.mainImage.gatsbyImageData}
              alt={node.mainImage.description}
            />
          </Col>
          <Col>
            <article>
              <h3>
                <Link to={`/blog/${node.url}`}>{node.title}</Link>
              </h3>
              <small className="text-secondary">Posted: {node.date}</small>
              <p>{node.description}</p>
              <Link to={`/blog/${node.url}`}>Read more about {node.title}</Link>
            </article>
          </Col>
        </Row>
      ))}
    </div>
  );
}
